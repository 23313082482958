var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "validation-observer",
    { ref: "observer" },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { wrap: "", justify: "start" } },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("h3", [_vm._v("Inscrição")]),
                _c("hr")
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "pt-2 pl-2", attrs: { outlined: "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { wrap: "" } },
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c("div", [
                              _c("p", [
                                _vm._v(
                                  " Área para realização de inscrição na modalidade de aluno especial, submissão de documentos pertinentes e informações complementares necessárias à solicitação de matrícula. "
                                )
                              ])
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-5", attrs: { wrap: "", justify: "start" } },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("h3", [_vm._v("Documentos")]),
                _c("hr")
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "pt-2 pl-2", attrs: { outlined: "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { wrap: "" } },
                        [
                          _c("v-col", { attrs: { cols: "11" } }, [
                            _c("div", [
                              _c("p", [
                                _vm._v(
                                  " Submissão de documentação prevista no regulamento. Ex.: Histórico, carta de recomendação, cursos externos e etc. Obs.: São permitidos apenas documentos no formato pdf com o tamanho máximo de 8mb. "
                                )
                              ])
                            ])
                          ]),
                          _c("v-col", { attrs: { cols: "1" } }, [
                            _c("p", [_c("FileUpload")], 1)
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-row"),
          _c(
            "v-row",
            { staticClass: "mt-5", attrs: { wrap: "", justify: "start" } },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("h3", [_vm._v("Opções")]),
                _c("hr")
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "selectedSubjects",
                              mode: "eager",
                              autocomplete: "false",
                              rules: "maxSubjects:3"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        disabled: _vm.progress,
                                        dense: "",
                                        outlined: "",
                                        hint:
                                          "Arraste e solte as disciplinas para re-ordená-las.",
                                        "persistent-hint": "",
                                        items: _vm.subjectCodes,
                                        "error-messages": errors,
                                        chips: "",
                                        "small-chips": "",
                                        multiple: "",
                                        color: "blue-grey lighten-2",
                                        label:
                                          "Selecione as disciplinas da mais prioritária para a menos prioritária",
                                        "no-data-text":
                                          "Nenhuma turma encontrada para solicitação de matrícula.",
                                        clearable: ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "selection",
                                            fn: function(data) {
                                              return [
                                                _c(
                                                  "draggable",
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        id: data.index,
                                                        list:
                                                          _vm.selectedSubjects,
                                                        "drag-class":
                                                          "draggable-css",
                                                        "ghost-class":
                                                          "ghost-css",
                                                        move: _vm.move
                                                      },
                                                      on: { change: _vm.change }
                                                    },
                                                    "draggable",
                                                    _vm.dragOptionsChips,
                                                    false
                                                  ),
                                                  [
                                                    _c(
                                                      "v-chip",
                                                      _vm._b(
                                                        {
                                                          key: data.item,
                                                          staticClass:
                                                            "draggable-css",
                                                          attrs: {
                                                            draggable: "",
                                                            small: "",
                                                            close: "",
                                                            "input-value":
                                                              data.selected,
                                                            color:
                                                              "complete" ===
                                                              "complete"
                                                                ? "blue lighten-3"
                                                                : "complete" ===
                                                                  "ongoing"
                                                                ? "blue"
                                                                : "green"
                                                          },
                                                          on: {
                                                            "click:close": function(
                                                              $event
                                                            ) {
                                                              return _vm.removeSelected(
                                                                data.item
                                                              )
                                                            },
                                                            mousedown: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                            },
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                            }
                                                          }
                                                        },
                                                        "v-chip",
                                                        data.attrs,
                                                        false
                                                      ),
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getIndex(
                                                                  data.item
                                                                )
                                                              ) +
                                                              "º opção: "
                                                          ),
                                                          _c("b", [
                                                            _vm._v(
                                                              _vm._s(data.item)
                                                            )
                                                          ])
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "item",
                                            fn: function(props) {
                                              return [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(props.item) +
                                                        ": " +
                                                        _vm._s(
                                                          _vm.getSubjectName(
                                                            props.item
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("v-list-item-subtitle", {
                                                      staticClass:
                                                        "d-flex justify-start",
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.getSubjectProfessor(
                                                            props.item
                                                          )
                                                        )
                                                      }
                                                    }),
                                                    _vm._l(
                                                      _vm.getSubjectSchedule(
                                                        props.item
                                                      ),
                                                      function(
                                                        schedule,
                                                        index
                                                      ) {
                                                        return _c(
                                                          "v-list-item-subtitle",
                                                          {
                                                            key: index,
                                                            staticClass:
                                                              "d-flex justify-start"
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "mr-2"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getDayOfWeek(
                                                                      schedule.dayWeek
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "mr-2"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    schedule.startTime
                                                                  ) +
                                                                    "-" +
                                                                    _vm._s(
                                                                      schedule.endTime
                                                                    )
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "mr-1"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Sala: " +
                                                                    _vm._s(
                                                                      schedule
                                                                        .classRoom
                                                                        .code
                                                                    ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      schedule
                                                                        .classRoom
                                                                        .college ==
                                                                        null
                                                                        ? ""
                                                                        : schedule
                                                                            .classRoom
                                                                            .college
                                                                            .name
                                                                    )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    )
                                                  ],
                                                  2
                                                ),
                                                _c("hr")
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.selectedSubjects,
                                        callback: function($$v) {
                                          _vm.selectedSubjects = $$v
                                        },
                                        expression: "selectedSubjects"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-combobox", {
                            attrs: {
                              disabled: _vm.progress,
                              dense: "",
                              outlined: "",
                              items: _vm.availableObjectives,
                              attach: "",
                              "item-text": "Candidate objective",
                              label: "Objetivo da inscrição",
                              counter: "200",
                              clearable: ""
                            },
                            model: {
                              value: _vm.candidateObjective,
                              callback: function($$v) {
                                _vm.candidateObjective = $$v
                              },
                              expression: "candidateObjective"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("h3", [_vm._v("POSCOMP")]),
                _c("hr")
              ]),
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("div", [
                  _vm._v(" O "),
                  _c("strong", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://www.sbc.org.br/noticias/10-slideshow-noticias/1971-faq-do-poscomp",
                          target: "_blank"
                        }
                      },
                      [_vm._v(" POSCOMP")]
                    )
                  ]),
                  _vm._v(
                    " é um exame nacional para ingresso na pós-graduação em Ciência da Computação. O exame é aplicado anualmente no segundo semestre. Recomendamos a todos os candidatos a aluno especial já graduados que façam o POSCOMP. Como o exame é aplicado apenas no segundo semestre, sabemos que vários candidatos não tiveram a oportunidade de realizá-lo e, por isso mesmo, o POSCOMP não é considerado obrigatório para avaliação. No entanto, recomendamos que todos os candidatos se programem para realizar o POSCOMP na próxima oportunidade possível. "
                  )
                ])
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              disabled: _vm.progress,
                              dense: "",
                              outlined: "",
                              block: "",
                              name: "poscompNumber",
                              label: "Número de inscrição"
                            },
                            model: {
                              value: _vm.poscompNumber,
                              callback: function($$v) {
                                _vm.poscompNumber = $$v
                              },
                              expression: "poscompNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              disabled: _vm.progress,
                              dense: "",
                              outlined: "",
                              block: "",
                              name: "poscompYear",
                              label: "Ano de Realização :",
                              type: "number",
                              hint: "Ex.: 2000"
                            },
                            model: {
                              value: _vm.poscompYear,
                              callback: function($$v) {
                                _vm.poscompYear = $$v
                              },
                              expression: "poscompYear"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "green",
                        name: "signup-btn",
                        outlined: "",
                        type: "submit",
                        large: "",
                        disabled:
                          _vm.selectedSubjects.length == 0 ||
                          _vm.candidateObjective == null ||
                          _vm.disabled
                      },
                      on: {
                        click: function($event) {
                          return _vm.sendSubjectsRequest()
                        }
                      }
                    },
                    [
                      _vm._v("Enviar Pedido "),
                      _c(
                        "v-icon",
                        { staticClass: "ml-2", attrs: { dark: "", small: "" } },
                        [_vm._v("send")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-progress-linear", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.progress,
                        expression: "progress"
                      }
                    ],
                    attrs: { indeterminate: "", color: "green" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }