<template>
  <validation-observer ref="observer">
    <v-container fluid>
      <v-row wrap justify="start">
        <v-col cols="12">
          <h3>Inscrição</h3>
          <hr />
        </v-col>
        <v-col cols="12">
          <v-card outlined class="pt-2 pl-2">
            <v-row wrap>
              <v-col cols="12">
                <div>
                  <p>
                    Área para realização de inscrição na modalidade de aluno
                    especial, submissão de documentos pertinentes e informações
                    complementares necessárias à solicitação de matrícula.
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row wrap justify="start" class="mt-5">
        <v-col cols="12">
          <h3>Documentos</h3>
          <hr />
        </v-col>
        <v-col cols="12">
          <v-card outlined class="pt-2 pl-2">
            <v-row wrap>
              <v-col cols="11">
                <div>
                  <p>
                    Submissão de documentação prevista no regulamento. Ex.:
                    Histórico, carta de recomendação, cursos externos e etc.
                    Obs.: São permitidos apenas documentos no formato pdf com o
                    tamanho máximo de 8mb.
                  </p>
                </div>
              </v-col>
              <v-col cols="1">
                <p><FileUpload></FileUpload></p
              ></v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row> </v-row>
      <v-row wrap justify="start" class="mt-5">
        <v-col cols="12">
          <h3>Opções</h3>
          <hr />
        </v-col>

        <v-col cols="12">
          <v-row wrap>
            <v-col cols="6">
              <validation-provider
                name="selectedSubjects"
                mode="eager"
                autocomplete="false"
                v-slot="{ errors }"
                rules="maxSubjects:3"
              >
                <v-autocomplete
                  :disabled="progress"
                  dense
                  outlined
                  v-model="selectedSubjects"
									hint="Arraste e solte as disciplinas para re-ordená-las."
									persistent-hint
                  :items="subjectCodes"
                  :error-messages="errors"
                  chips
									small-chips
                  multiple
                  color="blue-grey lighten-2"
                  label="Selecione as disciplinas da mais prioritária para a menos prioritária"
                  no-data-text="Nenhuma turma encontrada para solicitação de matrícula."
                  clearable
                >
                  <template v-slot:selection="data">
                    <draggable
                      :id="data.index"
                      :list="selectedSubjects"
                      drag-class="draggable-css"
                      ghost-class="ghost-css"
                      v-bind="dragOptionsChips"
                      :move="move"
                      @change="change"
                    >
                      <v-chip
                        class="draggable-css"
                        draggable
                        small
                        close
                        v-bind="data.attrs"
                        :key="data.item"
                        :input-value="data.selected"
                        :color="
                          'complete' === 'complete'
                            ? 'blue lighten-3'
                            : 'complete' === 'ongoing'
                            ? 'blue'
                            : 'green'
                        "
                        @click:close="removeSelected(data.item)"
                        @mousedown.stop
                        @click.stop
                      >
                        <span>
                          {{ getIndex(data.item) }}º opção:
                          <b>{{ data.item }}</b></span
                        >
                      </v-chip>
                    </draggable>
                  </template>

                  <template v-slot:item="props">
                    <v-list-item-content>
                      {{ props.item }}: {{ getSubjectName(props.item) }}
                      <v-list-item-subtitle
                        class="d-flex justify-start"
                        v-html="getSubjectProfessor(props.item)"
                      ></v-list-item-subtitle>
                      <v-list-item-subtitle
                        class="d-flex justify-start"
                        v-for="(schedule, index) in getSubjectSchedule(
                          props.item
                        )"
                        :key="index"
                      >
                        <span class="mr-2">{{
                          getDayOfWeek(schedule.dayWeek)
                        }}</span>
                        <span class="mr-2"
                          >{{ schedule.startTime }}-{{ schedule.endTime }}</span
                        >
                        <span class="mr-1"
                          >Sala: {{ schedule.classRoom.code }}
                          {{
                            schedule.classRoom.college == null
                              ? ""
                              : schedule.classRoom.college.name
                          }}</span
                        >
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <hr />
                  </template>
                </v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <v-combobox
                :disabled="progress"
                dense
                outlined
                :items="availableObjectives"
                attach
                item-text="Candidate objective"
                v-model="candidateObjective"
                label="Objetivo da inscrição"
                counter="200"
                clearable
              >
              </v-combobox>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <h3>POSCOMP</h3>
          <hr />
        </v-col>

        <v-col cols="12">
          <div>
            O
            <strong
              ><a
                href="https://www.sbc.org.br/noticias/10-slideshow-noticias/1971-faq-do-poscomp"
                target="_blank"
              >
                POSCOMP</a
              ></strong
            >
            é um exame nacional para ingresso na pós-graduação em Ciência da
            Computação. O exame é aplicado anualmente no segundo semestre.
            Recomendamos a todos os candidatos a aluno especial já graduados que
            façam o POSCOMP. Como o exame é aplicado apenas no segundo semestre,
            sabemos que vários candidatos não tiveram a oportunidade de
            realizá-lo e, por isso mesmo, o POSCOMP não é considerado
            obrigatório para avaliação. No entanto, recomendamos que todos os
            candidatos se programem para realizar o POSCOMP na próxima
            oportunidade possível.
          </div>
        </v-col>
        <v-col cols="12">
          <v-row wrap>
            <v-col cols="6">
              <v-text-field
                :disabled="progress"
                dense
                outlined
                block
                name="poscompNumber"
                label="Número de inscrição"
                v-model="poscompNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :disabled="progress"
                dense
                outlined
                block
                name="poscompYear"
                label="Ano de Realização :"
                type="number"
                hint="Ex.: 2000"
                v-model="poscompYear"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn
            color="green"
            name="signup-btn"
            outlined
            type="submit"
            large
            :disabled="
              selectedSubjects.length == 0 ||
              candidateObjective == null ||
              disabled
            "
            @click="sendSubjectsRequest()"
            >Enviar Pedido <v-icon dark small class="ml-2">send</v-icon></v-btn
          >
        </v-col>
        <v-col cols="12">
          <v-progress-linear
            indeterminate
            color="green"
            v-show="progress"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
  </validation-observer>
</template>
        
<script>
import { mapActions, mapState } from "vuex";
import { formatDate } from "@/utils/formatDate";

import FileUpload from "@/components/common/file/FileUpload";
import draggable from "vuedraggable";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("aggressive");

extend("maxSubjects", {
  validate(value, { maxSubjects }) {
    return value.length <= maxSubjects;
  },
  params: ["maxSubjects"],
  message: "Escolha no máximo {maxSubjects} disciplinas.",
});

export default {
  name: "CandidateEnrollment",
  props: ["candidate", "user"],
  components: { FileUpload, draggable, ValidationProvider, ValidationObserver },
  data() {
    return {
      show: false,
      disabled: true,
      showLoading: false,
      progress: false,
      dragged: { from: -1, to: -1, newIndex: -1 },
      subjects: [],
      selectedSubjects: [],
      subjectCodes: [],
      priorityDegreeByClasses: [],
      poscompNumber: null,
      poscompYear: null,
      candidateObjective: null,
      weekDays: ["INVALID", "DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SÁB"],
      availableObjectives: [
        "Sou aluno de graduação e quero cursar disciplinas da pós com equivalência para a graduação",
        "Tenho interesse em futuramente ser aluno regular de mestrado",
        "Tenho interesse em futuramente ser aluno regular de doutorado",
        "Sou graduado(a) em outra área e pretendo me habilitar profissionalmente em Computação",
        "Preciso dos conhecimentos da(s) disciplina(s) para meu atual emprego/estágio",
        "Outro (especifique): ",
      ],
    };
  },

  mounted() {
    this.getAsyncEnrollmentPeriod();
  },

  methods: {
    ...mapActions("candidateEnrollment", [
      "ActionGetEnrollmentPeriod",
      "ActionGetEnrollmentRequest",
      "ActionPostEnrollmentRequest",
    ]),
    ...mapActions("editCandidate", [
      "ActionPutCandidate",
      "ActionSetCandidate",
    ]),

    move(value) {
      this.dragged = {
        from: parseInt(value.from.id),
        to: parseInt(value.to.id),
        newIndex: value.draggedContext.futureIndex,
      };
    },

    change(value) {
      if (value.removed) {
        this.selectedSubjects.splice(
          this.dragged.to + this.dragged.newIndex,
          0,
          this.selectedSubjects[this.dragged.from]
        );
        if (this.dragged.from < this.dragged.to)
          // LTR
          this.selectedSubjects.splice(this.dragged.from, 1);
        // RTL
        else this.selectedSubjects.splice(this.dragged.from + 1, 1);
      }
    },

    //TODO: fazer um vetor com todos os dados do subject para que
    //O candidato possa pesquisar por horario e por professor tambem
    //atualmente so funciona a pesquisa por codigo da disciplina
    getIndex(code) {
      for (var i in this.selectedSubjects) {
        if (this.selectedSubjects[i] === code) {
          return parseInt(i) + 1;
        }
      }
      return this.selectedSubjects.length;
    },

    getSubjectName(code) {
      for (var subject of this.subjects) {
        if (code == subject.code) {
          return subject.name;
        }
      }
      return "";
    },

    getSubjectProfessor(code) {
      for (var subject of this.subjects) {
        if (code == subject.code) {
          return "<strong>" + subject.professor + "</strong>";
        }
      }
      return "";
    },

    getSubjectSchedule(code) {
      for (let subject of this.subjects) {
        if (code == subject.code) {
          return subject.classSchedules;
        }
      }
      return "";
    },

    getDayOfWeek(value) {
      return formatDate.getDayOfWeek(value);
    },

    async getAsyncEnrollmentPeriod() {
      try {
        this.disabled = true;
        this.progress = true;
        await this.ActionGetEnrollmentPeriod();
      } catch (err) {
        console.error("Erro ao buscar as disciplinas", err);
      } finally {
        this.disabled = false;
        this.progress = false;
      }
    },

    async sendSubjectsRequest() {
      let classes = this.subjects.filter((s) =>
        this.selectedSubjects.includes(s.code)
      );

      if (classes.length > 0) {
        let selectedClasses = [];
        let i = 1;

        classes.map((c) => {
          selectedClasses.push({ classId: c.id, priorityDegree: i });
          i++;
        });

        try {
          this.disabled = true;
          this.progress = true;

          let enrollmentRequest = {
            enrollmentPeriodId: this.enrollmentPeriod.id,
            priorityDegreeByClasses: selectedClasses,
            objective: this.candidateObjective,
            poscompNumber: this.poscompNumber,
            poscompYear: this.poscompYear,
          };

          await this.ActionPostEnrollmentRequest(enrollmentRequest);
          this.disabled = false;
          this.progress = false;
          this.closeEnrollment();
        } catch (e) {
          console.error(e);
          this.disabled = false;
          this.progress = false;
        }
      }
    },

    async removeSelected(item) {
      const index = this.selectedSubjects.findIndex(
        (v) => v.code === item.code
      );
      if (index >= 0) this.selectedSubjects.splice(index, 1);
      await this.$refs.observer.validate();
    },

    closeEnrollment() {
      this.$emit("close-enrollment");
    },
  },
  watch: {
    enrollmentPeriod(newValue) {
      if (newValue && newValue.classes.length > 0) {
        for (var currentClass of newValue.classes) {
          this.subjectCodes.push(currentClass.subject.code);

          let professors = "";
          currentClass.professors.map((p) => {
            professors += p.name + "   ";
          });

          this.subjects.push({
            id: currentClass.id,
            code: currentClass.subject.code,
            name: currentClass.subject.name,
            professor: professors,
            classSchedules: currentClass.classSchedules,
          });
        }
      }
    },
  },

  computed: {
    ...mapState("candidateEnrollment", ["enrollmentPeriod"]),

    dragOptionsChips() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost-css",
        sort: true,
      };
    },
  },
};
</script>

<style>
.ghost-css {
  opacity: 0;
  text-align: center;
  margin: 0 auto;
}

.draggable-css {
  text-align: center;
  margin: 0 auto;
}
</style>

